import isNil from "lodash/isNil";
import { removeLeftSlashes, removeRightSlashes } from "./string";
import qs from "qs";

export const buildURL = (path: string, baseURL = ""): URL => {
  const sanitizedBaseURL = removeRightSlashes(baseURL);
  const sanitizedPath = removeLeftSlashes(path);

  const fullPath = path.includes("http")
    ? path
    : `${sanitizedBaseURL}/${sanitizedPath}`;
  return new URL(fullPath);
};

export const hasUrlParam = (key: string, value?: any) => {
  const matchesKey = new URL(window.location.href).searchParams.has(key);
  const matchesValue =
    !value || new URL(window.location.href).searchParams.get(key) === value;

  return matchesKey && matchesValue;
};

export const hasFirstUrlParam = (param: string) =>
  window.location.href.includes(`?${param}`);

export const getSearchParams = () => new URL(window.location.href).searchParams;

export const parseSearchParams = (searchParams: string | URLSearchParams) => {
  const isString = typeof searchParams === "string";

  return qs.parse(isString ? searchParams : searchParams.toString());
};

export const stringifySearchParams = (searchParams: any) =>
  qs.stringify(searchParams);

export const updateSearchParam = (paramName: string, paramValue: any) =>
  updateSearchParamQuery(getSearchParams(), paramName, paramValue);

export const updateSearchParamQuery = (
  searchString: string | URLSearchParams,
  paramName: string,
  paramValue: any,
) => {
  const parsedSearchParams = parseSearchParams(searchString);

  const newSearchParams = {
    ...parsedSearchParams,
    [paramName]: paramValue,
  };

  return stringifySearchParams(newSearchParams);
};

export const objToQueryParam = (obj: any) => window.btoa(JSON.stringify(obj));

export const queryParamToObj = (queryParam: string) =>
  JSON.parse(window.atob(queryParam));

export const addQueryParams = (url: string, params: Record<string, any>) => {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === null) return;
    urlObj.searchParams.append(key, value);
  });
  return urlObj.toString();
};

/**
 * Utility to create query params without nill and empty string values
 */
export const createQueryParams = (
  params: Record<string, string | number | undefined>,
) => {
  const searchParams: Record<string, string> = {};
  Object.entries(params).forEach(([key, value]) => {
    if (isNil(value) || value === "") return;
    searchParams[key] = value.toString();
  });
  return qs.stringify(searchParams);
};
