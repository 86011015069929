import { pageData } from "@config/pages";
import { useUserRole } from "@hooks/useUserRole";
import { useMemo } from "react";

const sidebarRoutesCommon = [
  pageData["my/account"],
  // pageData["my/notifications"],
  pageData["my/team"],
  pageData["my/billing"],
]?.map((page) => ({
  ...page,
  path: `/${page.path}`, // add "/" to ensure a root-relative path
}));

const pageDocumentManagement = pageData["my/document-management"];

/**
 * Hooks to get user portal sidebar routes based on user permissions
 */
export const usePortalSidebarRoutes = () => {
  const { userPermissions, userPermissionsLoading } = useUserRole({
    fetchUserRole: true,
  });

  const sidebarRoutes = useMemo(() => {
    return [
      ...sidebarRoutesCommon,
      ...(userPermissions?.hasNavDocumentManagement
        ? [
            {
              ...pageDocumentManagement,
              path: `/${pageDocumentManagement.path}`,
            },
          ]
        : []),
    ];
  }, [userPermissions?.hasNavDocumentManagement]);

  return {
    // pass user permissions for simplicity
    userPermissions,
    userPermissionsLoading,
    sidebarRoutesByPermissions: sidebarRoutes,
    // FIXME: use sidebarRoutes instead of sidebarRoutesCommon when apis are ready
    sidebarRoutes: sidebarRoutesCommon,
    sidebarRoutesLoading: userPermissionsLoading,
  };
};
