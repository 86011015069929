import useDropdown from "../../hooks/useDropdown";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useOutsideClickIframeHelper } from "@hooks/useOutsideClickIframeHelper";
import { NavbarLink } from "@/types/navbar";
import classNames from "classnames";
import clsx from "clsx";
import React, { useRef, useMemo } from "react";
import { FiChevronDown } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";

type DropdownNavbarItem = {
  name: string;
  description: string;
  href: string;
  icon?: React.ElementType;
  isExternalSite?: boolean;
} & NavbarLink;

type DropdownNavbarProps = {
  title: string;
  items: DropdownNavbarItem[];
  handleClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: NavbarLink,
  ) => void;
};

const DropdownNavbar: React.FC<DropdownNavbarProps> = ({
  title,
  items,
  handleClick,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggleDropdown, setIsOpen } = useDropdown();
  const { pathname } = useLocation();
  const isHomePage = pathname === "/home";

  useOutsideClickIframeHelper(isOpen, "iframe");
  useOutsideClick(dropdownRef, () => setIsOpen(false));
  const isItemSelected = items.some((item) => pathname.startsWith(item.href));

  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: DropdownNavbarItem,
  ) => {
    setIsOpen(false);
    handleClick?.(e, item);
  };

  const itemList = useMemo(
    () =>
      items.map(({ icon: Icon, ...item }, index) => (
        <React.Fragment key={item.name}>
          <NavLink
            to={item.href}
            onClick={(e) => handleItemClick(e, item)}
            className={classNames(
              "group flex items-start p-4 transition-none ease-in-out hover:bg-gray-100 dark:bg-black focus:outline-none dark:hover:bg-ca-700 dark:focus:bg-ca-700 relative",
              {
                "text-black dark:text-white": isHomePage,
                "text-ca-gray-light dark:text-ca-text-100": !isHomePage,
                "rounded-b-lg": index === items.length - 1, // Add rounded bottom corners to the last item
                "after:content-[''] after:absolute after:inset-x-4 after:bottom-0 after:border-t after:border-gray-100 dark:after:border-ca-700 after:mt-4":
                  index < items.length - 1, // Add bottom border for all items except the last
                "hover:after:border-transparent": true, // Hides the border during hover
              },
            )}
          >
            <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-gray-100 dark:bg-black dark:group-hover:bg-ca-700 rounded-md">
              {Icon && (
                <Icon className="h-6 w-6 text-gray-500 dark:text-gray-300 transition-none" />
              )}
            </div>
            <div className="ml-4">
              <p className="text-base font-semibold text-gray-900 dark:text-white">
                {item.name}
              </p>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {item.description}
              </p>
            </div>
          </NavLink>
        </React.Fragment>
      )),
    [items, handleItemClick, isHomePage],
  );

  const isActive = isOpen || isItemSelected;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={clsx(
          "inline-flex items-center px-3 py-2 text-base font-medium focus:outline-none transition-colors",
          {
            "text-black dark:text-white": isHomePage || isActive,
            "bg-white dark:bg-black rounded-t-lg": isOpen,
            "text-ca-gray-light dark:text-ca-text-100 hover:text-black dark:hover:text-white":
              !isActive && !isHomePage,
          },
        )}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="dropdown-menu"
      >
        <span>{title}</span>
        <FiChevronDown
          className={classNames(
            "ml-2 h-5 w-5 transition-transform duration-150",
            { "rotate-180": isOpen },
          )}
        />
      </button>
      {isOpen && (
        <>
          {/* Connector element */}
          <div className="absolute left-0 top-full w-full h-3 bg-white dark:bg-black z-40 -mt-[1px]" />
          <div
            id="dropdown-menu"
            className="absolute left-0 w-72 bg-white dark:bg-gray-900 shadow-lg dark:border dark:border-ca-500 z-50 rounded-b-lg mt-2 transition-all duration-300"
          >
            {itemList}
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownNavbar;
