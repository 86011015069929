import {
  fetchUserSelfRole,
  fetchUserSelfInfo,
  TPermissionKeys,
  TUserRole,
  PERMISSIONS,
  PERMISSIONS_RESTRICTION,
  adminRoles,
  ROLES,
} from "@services/users";
import { useQuery } from "@tanstack/react-query";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import { isEmpty } from "lodash";

const mergePermissions = (
  src1: Record<string, string[]>,
  src2: Record<string, string[]>,
) => {
  const merged: Record<string, string[]> = {};
  const keys = Object.keys({ ...src1, ...src2 });

  keys.forEach((key) => {
    merged[key] = uniq([...(src1[key] ?? []), ...(src2[key] ?? [])]);
  });

  return merged;
};

const parseUserRoles = (roles: TUserRole[]) => {
  const allPermissions = {} as Record<
    TPermissionKeys,
    Record<string, string[]>
  >;
  roles.forEach((role) => {
    const rolePermissions = role?.permissions;

    const permissionsKeys = Object.keys(rolePermissions) as TPermissionKeys[];
    permissionsKeys?.forEach((permissionKey) => {
      allPermissions[permissionKey] = mergePermissions(
        allPermissions?.[permissionKey] ?? {},
        rolePermissions?.[permissionKey] ?? {},
      );
    });
  });

  const isAdmin = roles?.some((role) => adminRoles.includes(role.role));
  const isClientAdmin = roles?.some((role) => role.role === ROLES.CLIENT_ADMIN);
  const isClientLegalAdmin = roles?.some(
    (role) => role.role === ROLES.CLIENT_LEGAL_ADMIN,
  );

  const interests = allPermissions?.[PERMISSIONS["INTERESTS"]];
  const apiKeys = allPermissions?.[PERMISSIONS["API_KEYS"]];
  const alertsNotifications =
    allPermissions?.[PERMISSIONS["ALERTS_NOTIFICATIONS"]];
  const teams = allPermissions?.[PERMISSIONS["TEAM_PAGE"]];
  const transactions = allPermissions?.[PERMISSIONS["TRANSACTIONS"]];
  const tokens = allPermissions?.[PERMISSIONS["TOKENS"]];

  return {
    ...allPermissions,
    // account
    canSetOwnInterest: interests?.set?.includes(
      PERMISSIONS_RESTRICTION.USER_ONLY,
    ),
    canRegenerateApiKey: apiKeys?.regenerate?.includes(
      PERMISSIONS_RESTRICTION.USER_ONLY,
    ),

    // notifications
    canSetNotifications: !!alertsNotifications?.manage?.length,

    // team
    showTeamStats: false,
    canEditPermissions: false,
    canInviteTeam:
      teams?.invite?.includes(PERMISSIONS_RESTRICTION.ALL) && false,
    canRemoveTeam:
      teams?.remove?.includes(PERMISSIONS_RESTRICTION.ALL) && false,

    // billing
    showBillingPlan: isAdmin,
    canPurchaseToken: !!tokens?.purchase?.length,
    canSetLimits: isAdmin, // FIXME: proper permission checking
    showPurchaseHistory: false, // FIXME: proper permission checking
    showTransactionHistory: transactions?.view?.includes(
      PERMISSIONS_RESTRICTION.USER_ONLY,
    ),
    showTransactionHistoryAll: transactions?.view?.includes(
      PERMISSIONS_RESTRICTION.ALL,
    ),
    showSubscriptions: false, // FIXME: proper permission checking

    // document management
    showDocumentContracts: isClientAdmin || isClientLegalAdmin, // FIXME: proper permission checking
    editDocumentContracts: isClientLegalAdmin,

    // navigation
    hasNavDocumentManagement: isAdmin, // FIXME: proper permission checking
  };
};

export type TParsedUserRoles = ReturnType<typeof parseUserRoles>;

export const useUserRole = ({
  fetchUserRole = false,
  fetchUserInfo = false,
}: {
  fetchUserRole?: boolean;
  fetchUserInfo?: boolean;
}) => {
  const userSelfRoles = useQuery({
    queryKey: ["user-self-roles"],
    queryFn: () => fetchUserSelfRole(),
    retry: false,
    enabled: fetchUserRole,
  });

  const userPermissions = useMemo(() => {
    if (userSelfRoles?.data && !isEmpty(userSelfRoles?.data)) {
      return parseUserRoles([userSelfRoles?.data]);
    }

    return {} as TParsedUserRoles;
  }, [userSelfRoles?.data]);

  const userSelfInfo = useQuery({
    queryKey: ["user-self-info"],
    queryFn: () => fetchUserSelfInfo(),
    retry: false,
    enabled: fetchUserInfo,
  });

  return {
    userSelfRoles,
    userPermissions,
    userPermissionsLoading: userSelfRoles.isLoading,
    userSelfInfo,
  };
};
