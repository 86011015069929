import { apiFetch } from "@/services/api";
import config from "@config/config";

const USERS_BASE_URL = `${config.carcAuth.url}/users`;

export enum ROLES {
  USER_UNRESTRICTED = "User Unrestricted",
  CLIENT_ADMIN = "Client Admin",
  CLIENT_LEGAL_ADMIN = "Client Legal Admin",
  VENDOR_ADMIN = "Vendor Admin",
}

export const adminRoles: `${ROLES}`[] = [
  ROLES.CLIENT_ADMIN,
  ROLES.CLIENT_LEGAL_ADMIN,
  ROLES.VENDOR_ADMIN,
];

export enum PERMISSIONS {
  INTERESTS = "interests",
  PASSWORD = "password",
  ALERTS_NOTIFICATIONS = "alerts_notifications",
  TOKENS = "tokens",
  TRANSACTIONS = "transactions",
  TEAM_PAGE = "team_page",
  API_KEYS = "api_keys",
  BALANCE = "balance",
  WORKBOOKS = "workbooks",
}

export enum PERMISSIONS_RESTRICTION {
  ALL = "all",
  INDIVIDUAL = "individual",
  NO_LIMIT = "no_limit",
  USER_ONLY = "user_only",
  WITHIN_TEAM = "within_team",
}

export type TPermissionKeys = `${PERMISSIONS}`;

export type TUserRole = {
  id: string;
  role: `${ROLES}`;
  permissions: {
    [key in TPermissionKeys]?: {
      [key: string]: string[];
    };
  };
};

export interface TUserSelfInfo {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export const fetchUserSelfRole = async () => {
  return apiFetch<TUserRole>(`${USERS_BASE_URL}/self/role`);
};

export const fetchUserSelfInfo = async () =>
  apiFetch<TUserSelfInfo>(`${USERS_BASE_URL}/self/info`);
