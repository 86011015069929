import { CustomFlowbiteTheme, getTheme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      info: "border border-transparent bg-black text-white enabled:hover:bg-black enabled:hover:text-white disabled:bg-stone-300 disabled:text-ca-300 dark:border-white focus:ring-0",
      text: "text-ca-500 dark:text-ca-100 transition-none hover:text-ca-800 dark:hover:text-ca-200",
      gray: "border border-ca-200 bg-ca-200 text-ca-800 enabled:hover:bg-ca-100 enabled:hover:border-ca-100 enabled:hover:text-ca-700 dark:border-ca-700 dark:bg-ca-700 dark:text-ca-200 dark:enabled:hover:bg-black dark:enabled:hover:border-ca-800 dark:enabled:hover:text-ca-100",
    },
    pill: {
      on: twMerge(
        "rounded-full bg-white border border-black text-black font-normal	dark:bg-black dark:border-white dark:text-white",
        "hover:enabled:bg-black hover:text-white hover:enabled:text-bold dark:hover:enabled:bg-zinc-600 dark:hover:enabled:text-black dark:hover:enabled:border-transparent dark:hover:enabled:text-white dark:hover:border-white hover:font-bold",
        "disabled:opacity-30 disabled:bg-white dark:disabled:bg-stone-300 dark:disabled:border-neutral-400 dark:disabled:text-black disabled:font-normal",
      ),
    },
  },
  card: {
    root: {
      ...getTheme().card.root,
      base: twMerge(
        getTheme().card.root.base,
        "bg-white dark:bg-ca-600 hover:shadow-card-hover dark:hover:shadow-card-hover-dark dark:border-ca-500",
      ),
      href: twMerge(
        getTheme().card.root.href,
        "hover:bg-white dark:hover:bg-ca-600 dark:bg-ca-600 hover:shadow-card-hover dark:hover:shadow-card-hover-dark dark:border-ca-500",
      ),
    },
  },
  dropdown: {
    floating: {
      style: {
        auto: "border-none -mt-4 bg-white dark:bg-ca-600 shadow-md",
      },
      item: {
        container: twMerge(
          getTheme().dropdown.floating.item.container,
          "bg-white dark:bg-ca-600",
        ),
        base: twMerge(
          getTheme().dropdown.floating.item.base,
          "py-4 px-10 font-semibold text-black dark:text-white flex focus:bg-ca-600 focus:text-white",
        ),
      },
      divider: twMerge(
        getTheme().dropdown.floating.divider,
        "my-0 bg-gray-200 dark:bg-white",
      ),
      target: twMerge(
        getTheme().dropdown.floating.target,
        "bg-transparent !border-transparent dark:aria-expanded:bg-ca-600 aria-expanded:bg-white dark:enabled:hover:bg-ca-600 enabled:hover:bg-white aria-expanded:shadow-md text-black hover:!text-black dark:text-white aria-expanded:rounded-b-none",
      ),
    },
    inlineWrapper: "inline-flex items-center",
  },
  label: {
    root: {
      base: twMerge(
        getTheme().label.root.base,
        "mb-3 inline-block text-black font-normal",
      ),
    },
  },
  spinner: {
    color: {
      info: "fill-black dark:fill-white",
    },
  },
  textarea: {
    colors: {
      info: "bg-white border-[#A4A4A4] focus:ring-0 focus:border-[#A4A4A4] rounded-[10px] dark:bg-ca-700 dark:border-ca-500 placeholder-[#9F9F9F] dark:placeholder-white dark:text-white",
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          info: "bg-white border-[#A4A4A4] focus:ring-0 focus:border-[#A4A4A4] rounded-[10px] dark:bg-ca-700 dark:border-ca-500 placeholder-[#9F9F9F] dark:placeholder-white dark:text-white",
          gray: twMerge(
            getTheme().textInput.field.input.colors.gray,
            "border-gray-400 bg-white focus:border-black focus:ring-black dark:focus:border-black dark:focus:ring-black dark:bg-ca-600 dark:border-ca-500 dark:text-white",
          ),
        },
      },
    },
  },
  tabs: {
    tablist: {
      base: twMerge(
        getTheme().tabs.tablist.base,
        "bg-white dark:bg-black px-5",
      ),
    },
    tabpanel: twMerge(
      getTheme().tabs.tabpanel,
      "bg-[#A4AD9C] bg-opacity-15 px-5",
    ),
  },
  checkbox: {
    root: {
      base: twMerge(getTheme().checkbox.root.base, "h-6 w-6 text-2xl"),
      color: {
        default:
          "text-black dark:text-transparent focus:ring-black dark:ring-offset-white dark:focus:ring-black border-black dark:border-white bg-white dark:bg-transparent",
      },
    },
  },
  toggleSwitch: {
    toggle: {
      base: "relative rounded-full border after:absolute after:rounded-full after:bg-white after:transition-all",
      checked: {
        color: {
          info: "border-black bg-black",
        },
      },
    },
  },
  badge: {
    root: {
      ...getTheme().badge.root,
      base: twMerge(getTheme().badge.root.base, "font-normal rounded-sm"),
      color: {
        info: "bg-white dark:bg-ca-600 text-black dark:text-white hover:bg-gray-50 dark:hover:bg-ca-500",
      },
    },
    icon: {
      off: "rounded-sm px-2 py-1.5",
    },
  },
  drawer: {
    root: {
      base: twMerge(getTheme().drawer.root.base, "dark:bg-ca-600"),
      backdrop: twMerge(getTheme().drawer.root.backdrop, "dark:bg-black/80"),
    },
    header: {
      inner: {
        titleText: twMerge(
          getTheme().drawer.header.inner.titleText,
          "text-black dark:text-white text-xl pl-6 mt-4",
        ),
        closeButton: twMerge(
          getTheme().drawer.header.inner.closeButton,
          "w-fit py-5 px-4 mt-4",
        ),
      },
    },
  },
  sidebar: {
    root: {
      inner: twMerge(getTheme().sidebar.root.inner, "dark:bg-transparent"),
    },
    item: {
      base: twMerge(
        getTheme().sidebar.item.base,
        "hover:dark:bg-ca-500 cursor-pointer p-0 rounded-none",
      ),
      content: {
        base: twMerge(getTheme().sidebar.item.content.base, "px-6"),
      },
    },
  },
  modal: {
    root: {
      base: twMerge(getTheme().modal.root.base, "dark:backdrop-blur-sm"),
    },
    header: {
      base: twMerge(getTheme().modal.header.base, "dark:bg-black"),
      popup: "border-b-0",
      close: {
        base: "rounded-2xl p-1 -m-1 bg-transparent text-ca-800 hover:bg-ca-200 dark:hover:bg-ca-500 dark:text-ca-100",
        icon: "text-2xl",
      },
    },
    body: {
      base: twMerge(getTheme().modal.body.base, "dark:bg-black"),
    },
    footer: {
      base: twMerge(
        getTheme().modal.footer.base,
        "justify-end dark:bg-black pt-0",
      ),
    },
  },
  pagination: {
    pages: {
      previous: {
        base: twMerge(getTheme().pagination.pages.previous.base, "py-1.5 px-2"),
      },
      next: {
        base: twMerge(getTheme().pagination.pages.next.base, "py-1.5 px-2"),
      },
      selector: {
        base: twMerge(getTheme().pagination.pages.selector.base, "py-1.5 px-1"),
      },
    },
  },
};

export default customTheme;
