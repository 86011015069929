import { useEffect } from "react";

const dataKey = "data-wrapper-id";
const dataValue = "container-wrapper-for-iframe";
const dataSelector = `[${dataKey}="${dataValue}"]`;

const createEmptyContainer = () => {
  const div = document.createElement("div");
  div.className = "absolute top-0 right-0 bottom-0 left-0";
  div.setAttribute(dataKey, dataValue);
  return div;
};

/**
 * Hooks to add wrapper div on top of iframe to close it on click outside
 *
 * The wrapper div is removed on unmount
 */
export const useOutsideClickIframeHelper = (
  show: boolean,
  querySelector: string,
) => {
  useEffect(() => {
    const elements = document.querySelectorAll(querySelector);

    elements.forEach((element) => {
      if (show && element.parentElement) {
        // inject the wrapper beside the iframe
        element.parentElement.appendChild(createEmptyContainer());
      } else {
        // remove all wrappers
        const wrappers = document.querySelectorAll(dataSelector);
        wrappers.forEach((element) => element.remove());
      }
    });

    return () => {
      const wrappers = document.querySelectorAll(dataSelector);
      // remove all wrappers on unmount
      wrappers.forEach((element) => element.remove());
    };
  }, [querySelector, show]);
};
