import { useMemo } from "react";
import { SiteNavbarItems, getNavbarItem } from "@config/navbarItems";
import { NavbarDropdown, NavbarItem, Pages } from "@/types/navbar";
import { useFeatureAccess } from "./useFeatureAccess";

export function usePages() {
  const { canAccessFeature, isLoading } = useFeatureAccess();
  const navbarItems = useMemo(() => {
    const filterNavbarItems = (items: NavbarItem[]) => {
      return items.reduce((accessibleItems: NavbarItem[], item) => {
        if (item.type === "dropdown" && item.items) {
          const child = filterNavbarItems(item.items);
          if (child.length) {
            accessibleItems.push({ ...item, items: child } as NavbarDropdown);
          }
        } else {
          // if there's no fAccessKey restriction, allow access
          const userHasAccess = item.fAccessKey
            ? canAccessFeature(item.fAccessKey)
            : true;

          if (userHasAccess) {
            accessibleItems.push({
              ...item,
              canAccess: true,
            });
          }
        }
        return accessibleItems;
      }, []);
    };
    return filterNavbarItems(SiteNavbarItems);
  }, [canAccessFeature]);
  const canAccessExplorerPage = useMemo(() => {
    const entities_page = getNavbarItem(Pages.entities);
    return (
      entities_page?.fAccessKey && canAccessFeature(entities_page.fAccessKey)
    );
  }, [canAccessFeature]);
  return { navbarItems, isLoading, canAccessExplorerPage };
}
