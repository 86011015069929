import { Component, ErrorInfo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  className?: string;
}

interface State {
  hasError: boolean;
}

export const ErrorBoundaryContent = ({ className }: { className?: string }) => {
  const { t } = useTranslation("");

  return (
    <div
      className={twMerge(
        "h-full flex items-center justify-center bg-gray-50 dark:bg-gray-700",
        className,
      )}
    >
      <div className="rounded-lg shadow bg-white dark:bg-gray-800 p-8 flex flex-col items-center justify-center dark:text-slate-100">
        <strong>{t("error_boundary.title")}</strong>
        <p>{t("error_boundary.description")}</p>

        <a
          href="/"
          className="mt-8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("error_boundary.return_to_home_page")}
        </a>
      </div>
    </div>
  );
};

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryContent className={this.props.className} />;
    }

    return this.props.children;
  }
}
