interface LoadingSpinnerProps {
  sm?: boolean;
  xsm?: boolean;
}

export default function LoadingSpinner({
  sm = false,
  xsm = false,
}: LoadingSpinnerProps) {
  const easeInOut = (t: number) => 0.5 * (1 - Math.cos(Math.PI * t));

  const pauseDuration = 0; // Pause at the start in seconds
  const animationDuration = 1.25; // Duration of the rotation
  const totalDuration = animationDuration + pauseDuration; // Total duration including pause
  const totalRotation = 1800; // Total degrees rotated
  const steps = 100; // Number of steps for smooth animation

  const generateAnimationData = (center: string, isClockwise: boolean) => {
    const values = [];
    const keyTimes = [];
    const pauseEnd = pauseDuration / totalDuration; // Normalized time when pause ends

    // Initial pause
    values.push(`0 ${center}`);
    keyTimes.push("0.000000");

    values.push(`0 ${center}`);
    keyTimes.push(pauseEnd.toFixed(6));

    for (let i = 0; i <= steps; i++) {
      const adjusted_t = i / steps; // Normalized time from 0 to 1 for the rotation
      const easedT = easeInOut(adjusted_t);

      // Overall normalized time during the animation
      const t = pauseEnd + adjusted_t * (animationDuration / totalDuration);

      const rotation = isClockwise
        ? easedT * totalRotation
        : -easedT * totalRotation;

      values.push(`${rotation.toFixed(2)} ${center}`);
      keyTimes.push(t.toFixed(6));
    }

    // Ensure the final keyTime is exactly 1.0
    keyTimes[keyTimes.length - 1] = "1.000000";

    return {
      values: values.join(";"),
      keyTimes: keyTimes.join(";"),
    };
  };

  const topLeft = generateAnimationData("7.7 7.7", true);
  const topRight = generateAnimationData("24.3 7.7", false);
  const bottomLeft = generateAnimationData("7.7 24.3", false);
  const bottomRight = generateAnimationData("24.3 24.3", true);

  return (
    <svg
      width={xsm ? "18" : sm ? "32" : "96"}
      height={xsm ? "18" : sm ? "32" : "96"}
      viewBox="-2 -2 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Top Left Arc - Clockwise */}
      <path
        fill="#FF5A00"
        d="m1.549 3.68 2.536 2.533.077.496h.001a3.675 3.675 0 0 0 .941 3.591 3.693 3.693 0 0 0 5.212 0 3.68 3.68 0 0 0 0-5.204 3.691 3.691 0 0 0-3.597-.939l-.497-.08-2.536-2.53v-.418c2.97-1.814 6.91-1.44 9.478 1.123 3.008 3.003 3.008 7.89 0 10.893-3.007 3.003-7.901 3.003-10.909 0C-.312 10.58-.687 6.645 1.13 3.68h.419Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values={topLeft.values}
          keyTimes={topLeft.keyTimes}
          calcMode="linear"
          dur={`${totalDuration}s`}
          repeatCount="indefinite"
        />
      </path>

      {/* Top Right Arc - Counter-clockwise */}
      <path
        fill="#FF5A00"
        d="m30.451 3.68-2.537 2.533-.076.496h-.002a3.675 3.675 0 0 1-.94 3.591 3.693 3.693 0 0 1-5.212 0 3.68 3.68 0 0 1 0-5.204 3.691 3.691 0 0 1 3.597-.939l.497-.08 2.536-2.532v-.418c-2.97-1.814-6.91-1.44-9.478 1.124-3.008 3.003-3.008 7.89 0 10.892 3.007 3.003 7.901 3.003 10.909 0 2.567-2.563 2.941-6.499 1.124-9.464h-.418v.001Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values={topRight.values}
          keyTimes={topRight.keyTimes}
          calcMode="linear"
          dur={`${totalDuration}s`}
          repeatCount="indefinite"
        />
      </path>

      {/* Bottom Left Arc - Counter-clockwise */}
      <path
        fill="#FF5A00"
        d="m1.548 28.32 2.537-2.533.076-.496h.002a3.675 3.675 0 0 1 .94-3.591 3.693 3.693 0 0 1 5.212 0 3.68 3.68 0 0 1 0 5.204 3.691 3.691 0 0 1-3.597.939l-.497.08-2.536 2.532v.418c2.97 1.814 6.911 1.44 9.478-1.124 3.008-3.003 3.008-7.89 0-10.892-3.007-3.003-7.901-3.003-10.908 0C-.313 21.42-.687 25.356 1.13 28.32h.418v-.001Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values={bottomLeft.values}
          keyTimes={bottomLeft.keyTimes}
          calcMode="linear"
          dur={`${totalDuration}s`}
          repeatCount="indefinite"
        />
      </path>

      {/* Bottom Right Arc - Clockwise */}
      <path
        fill="#FF5A00"
        d="m30.451 28.32-2.537-2.533-.076-.496h-.002a3.675 3.675 0 0 0-.94-3.591 3.693 3.693 0 0 0-5.212 0 3.68 3.68 0 0 0 0 5.204 3.691 3.691 0 0 0 3.597.939l.497.08 2.536 2.532v.418c-2.97 1.814-6.91 1.44-9.478-1.124-3.008-3.003-3.008-7.89 0-10.892 3.007-3.003 7.901-3.003 10.909 0 2.567 2.563 2.941 6.499 1.124 9.464h-.418v-.001Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values={bottomRight.values}
          keyTimes={bottomRight.keyTimes}
          calcMode="linear"
          dur={`${totalDuration}s`}
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}
